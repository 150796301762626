<template>
  <v-card
    color="secondary"
    shaped
    @click="next"
  >
    <v-card-text>
      <v-row align="center">
        <v-col cols="4">
          <v-avatar
            size="75"
          >
            <img
              :src="image"
            >
          </v-avatar>
        </v-col>

        <v-col
          cols="8"
          text-align="left"
        >
          <h3 class="quaternary--text">{{ title }}</h3>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { path } from '@/utils/image';

export default {
  name: 'TestSelection',
  props: {
    id: {
      type: String,
    },
  },
  computed: {
    title() {
      return this.$t(`${this.id}.title`);
    },
    image() {
      return path(this.id);
    },
  },
  methods: {
    next() {
      // test is initiated through router navigation guard!
      this.$router.push({ name: 'Test', params: { test: this.id } });
    },
  },
};
</script>
